<template>
  <div class="main-container">
    <div class="main-heading">
      <h2>{{ "Modifier" }}</h2>
      <Button
        text="Submit"
        :loading="isLoading"
        type="success"
        :onClick="submit"
      />
    </div>
    <div class="content-container">
      <div style="padding: 30px">
        <div class="form-field">
          <h5>Name</h5>
          <select class="input" v-model="selectedLanguage">
            <option
              v-for="language in languages"
              v-bind:key="language.code"
              :value="language.code"
            >
              {{ language.name }}
            </option>
          </select>
        </div>
        <div class="form-field">
          <h5>Name</h5>
          <input
            class="input"
            v-model="modifier.localizables.name[selectedLanguage]"
          />
        </div>
        <div class="form-field">
          <h5>Description</h5>
          <textarea
            class="input"
            v-model="modifier.localizables.desc[selectedLanguage]"
          />
        </div>
        <div class="form-field">
          <h5>Modifier Type</h5>
          <select class="input" v-model="modifier.modifier_type">
            <option
              v-for="option in modifierConstants.MODIFIER_TYPES"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ option.text || "Default" }}
            </option>
          </select>
        </div>
        <div>
          <div class="form-field">
            <h5>Icon Asset</h5>
            <multiselect
              class="input"
              v-model="modifier.icon_asset"
              :options="assetOptions"
              :multiple="false"
            />
          </div>
        </div>
      </div>
      <div style="padding: 30px">
        <div
          class="form-field"
          v-for="field in Object.keys(modifierConstants.FIELD_TYPE_MAP).filter(
            (x) =>
              modifierConstants.FIELD_TYPE_MAP[x].types.includes(
                modifier.modifier_type
              )
          )"
          v-bind:key="field"
        >
          <h5>{{ modifierConstants.FIELD_TYPE_MAP[field].text }}</h5>
          <input class="input" type="number" v-model="modifier[field]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, ref, onBeforeMount, onUpdated, watch, computed } from "vue";
import { useStore } from "vuex";
import Button from "../../../components/common/Button.vue";
import dispatchMap from "@/constants/dispatchMap";
import modifierConstants from "@/constants/modifierConstants";
import { useRoute, useRouter } from "vue-router";
import modifierSchema from "@/schemas/modifier";
import languageConstants from "@/constants/languagesTemp";

export default {
  name: "Modifier",
  methods: {},
  components: {
    Button,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const modifier = ref(modifierSchema());
    const usedPages = ["modifiers"];
    const selectedLanguage = ref("en");
    const isLoading = ref(false);

    const assetList = computed(() => {
      return [
        {
          doc_ref: undefined
        },
        ...store.getters['assets/getAssets'].files
      ]
    })

    onBeforeMount(() =>
      usedPages.forEach((page) => {
        dispatchMap[page].forEach((dispatchStr) => store.dispatch(dispatchStr));
      })
    );

    onUpdated(() => {
      store.dispatch("loader/loadingStatus", false);
    });
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    watch(
      () => store.getters["gameModifiers/getGameModifiers"],
      (value) => {
        if (route.query.id && value.length > 0)
          modifier.value = JSON.parse(
            JSON.stringify([...value].find((x) => x.id === route.query.id))
          );

        console.log(modifier.value);
      },
      { immediate: true }
    );

    const prepareForSubmit = () => {
      const data = JSON.parse(JSON.stringify(modifier.value));

      Object.keys(modifierConstants.FIELD_TYPE_MAP).forEach((key) => {
        if (
          !modifierConstants.FIELD_TYPE_MAP[key].types.includes(
            data.modifier_type
          )
        )
          delete data[key];
      });

      return data;
    };

    const submit = () => {
      const result = prepareForSubmit();
      isLoading.value = true;

      if (route.query.id) {
        store
          .dispatch("gameModifiers/updateGameModifier", {
            id: route.query.id,
            data: { ...result },
          })
          .then((res) => {
            if (res) router.push({ name: "ModifiersMain" });
          })
          .finally(() => (isLoading.value = false));
      } else {
        store
          .dispatch("gameModifiers/addGameModifier", {
            data: { ...result },
          })
          .then((res) => {
            if (res) router.push({ name: "ModifiersMain" });
          })
          .finally(() => (isLoading.value = false));
      }
    };

    const assetOptions = computed(() => {
      let assetArray = []
      assetList.value.forEach(asset => {
        if(asset.doc_ref === undefined){
          assetArray.push("DEFAULT BANNER")
        }
        else{
          assetArray.push(asset.doc_ref)
        }
      })
      return assetArray
    });

    return {
      modifierConstants,
      modifier,
      selectedLanguage,
      languages: languageConstants.languages,
      submit,
      isLoading,
      assetOptions
    };
  },
};
</script>


<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.main-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 0 25px;
}

.input {
  width: 300px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.form-field {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-field-row {
  margin: 10px;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}
</style>