export default {
    MODIFIER_TYPES: [
        {
            text: 'Attack Speed Tiles',
            value: 1
        },
        {
            text: 'Stunning Tiles',
            value: 2
        },
        {
            text: 'Merge Fail',
            value: 3
        },
        {
            text: 'Merge Rank Boost',
            value: 4
        },
        {
            text: 'Mana Blocker Tile',
            value: 5
        },
        {
            text: 'Summon Instant Destroy',
            value: 6
        },
        {
            text: 'Mana Gift',
            value: 7
        },
        {
            text: 'Merge Rank Damage Modifier',
            value: 8
        },
        {
            text: 'Neighbor Count Based Rank Decrease',
            value: 9
        },
        {
            text: 'Corner Unit Damages',
            value: 10
        },
        {
            text: 'Change Unit Type',
            value: 11
        },
        {
            text: 'Miniboss Freeze',
            value: 12
        },
        {
            text: 'New Unit Sleep',
            value: 13
        },
        {
            text: 'Slower Spells',
            value: 14
        },
        {
            text: 'Miniboss Attack Speed',
            value: 15
        },
        {
            text: 'Monster Damage Decrease',
            value: 16
        },
        {
            text: 'Boss Merge Rank Modifier',
            value: 17
        },
        {
            text: 'Miniboss Immunity',
            value: 18
        },
        {
            text: 'Damage Tiles',
            value: 19
        },
        {
            text: 'Upgrade Discount',
            value: 20
        },
        {
            text: 'Spell Generosity',
            value: 21
        },
        {
            text: 'Power of Upgrade',
            value: 22
        },
        {
            text: 'Double Income',
            value: 23
        },
        {
            text: 'Mana Tiles',
            value: 24
        },
        {
            text: 'Weakening Tiles',
            value: 25
        },
        {
            text: 'Empowering Tiles',
            value: 26
        },
        {
            text: 'Cursed Tiles',
            value: 27
        },
        {
            text: 'Grim Tiles',
            value: 28
        },
        {
            text: 'Artillery Strike',
            value: 29
        },
        {
            text: 'Slowing Merge',
            value: 30
        },
        {
            text: 'Swap Heroes',
            value: 31
        }
    ],
    FIELD_TYPE_MAP: {
        amount: {
            types: [7, 17, 24],
            text: "Amount"
        },
        tile_count: {
            types: [1, 2, 5, 19, 24, 25, 26, 27, 28, 29],
            text: "Tile Count"
        },
        interval: {
            types: [9, 11],
            text: "Interval"
        },
        damage_buff: {
            types: [8, 10, 16, 19, 22, 25, 26],
            text: "Damage Buff"
        },
        speed_buff: {
            types: [1, 15],
            text: "Speed Buff"
        },
        chance: {
            types: [2, 3, 4, 6, 28, 29],
            text: "Chance (0 - 100)"
        },
        effect_duration: {
            types: [2, 5, 13, 30],
            text: "Duration"
        },
        neighbor_count: {
            types: [9],
            text: "Least Neighbor Count"
        },
        merge_rank_type: {
            types: [8],
            text: "Merge Rank Type (0 for even 1 for odd)"
        },
        percentage: {
            types: [14, 20, 21, 27],
            text: "Percentage (0 - 100)"
        },
        unit_count: {
            types: [17],
            text: "Affected Unit Count"
        },
        movement_speed_decrease: {
            types: [30],
            text: 'Movement Speed Decrease / Merge'
        },
        slow_cap: {
            types: [30],
            text: 'Slow Cap'
        }
    },
    MERGE_RANK_TYPES: [
        {
            text: "Even",
            value: 0
        },
        {
            text: "Odd",
            value: 1
        }
    ]
}